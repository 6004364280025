require('dotenv').config();

module.exports = {
  wooCommerce: {
    url: process.env.WOO_COMMERCE_URL,
    consumerKey: process.env.WOO_COMMERCE_CONSUMER_KEY,
    consumerSecret: process.env.WOO_COMMERCE_CONSUMER_SECRET
  },
  dokan: {
    url: process.env.DOKAN_URL,
    consumerKey: process.env.DOKAN_CONSUMER_KEY,
    consumerSecret: process.env.DOKAN_CONSUMER_SECRET
  },
  database: {
    host: process.env.DB_HOST,
    user: process.env.DB_USER,
    password: process.env.DB_PASSWORD,
    database: process.env.DB_NAME
  },
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL
};