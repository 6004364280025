import React, { useState } from 'react';
import './Popup.css';
import okSign from './img/symbol-ok-30.png';
import closeIcon from './img/close-24.png';
import acceptIcon from './img/accept-67.png';
import axios from 'axios';

const Popup = ({ content, onClose, onNext, onReject, products = [], eventId }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleProductSelection = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId]
    );
  };

  const handleNext = async () => {
  if (content === 'products' && selectedProducts.length === 0) {
    setErrorMessage('Please select at least one product');
    return;
  }

  setIsLoading(true);
  setErrorMessage('');

  try {
    if (content === 'products') {
      const response = await axios.post(
        `https://srv493134.hstgr.cloud/api/events/${eventId}/products`,
        { products: selectedProducts },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update status');
      }
      
      console.log('Status updated to Valid for event:', eventId);
    }
    
    onNext(selectedProducts);
  } catch (error) {
    console.error('Error updating event status:', error);
    setErrorMessage(error.response?.data?.error || 'Failed to update status. Please try again.');
  } finally {
    setIsLoading(false);
  }
};

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
        
        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}

        {content === 'initial' && (
          <div className="popup-initial">
            <h2>Invitation to Live Event</h2>
            <p>You have been invited to participate in a live shopping event.</p>
            <div className="button-group">
              <button 
                className="next-button" 
                onClick={handleNext}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Accept & Continue'}
              </button>
              <button 
                className="reject-button" 
                onClick={onReject}
                disabled={isLoading}
              >
                Reject
              </button>
            </div>
          </div>
        )}

        {content === 'products' && (
          <div className="popup-products">
            <h2>Select Your Products</h2>
            <p>Choose which products you want to feature in the live event</p>
            
            {products.length === 0 ? (
              <div className="no-products">
                <p>No products available for this vendor.</p>
              </div>
            ) : (
              <div className="product-grid">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className={`product-item ${selectedProducts.includes(product.id) ? 'selected' : ''}`}
                    onClick={() => handleProductSelection(product.id)}
                  >
                    <div className="product-image-container">
                      <img 
                        src={product.images?.[0]?.src || 'https://via.placeholder.com/150'} 
                        alt={product.name}
                        className="product-image"
                        onError={(e) => {
                          e.target.src = 'https://via.placeholder.com/150';
                        }}
                      />
                      {selectedProducts.includes(product.id) && (
                        <img src={okSign} alt="Selected" className="ok-sign" />
                      )}
                    </div>
                    <div className="product-info">
                      <h3 className="product-name">{product.name}</h3>
                      <p className="product-price">
                        {product.price ? `$${product.price}` : 'Price not available'}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="button-group">
              <button 
                className="next-button" 
                onClick={handleNext}
                disabled={selectedProducts.length === 0 || isLoading}
              >
                {isLoading ? 'Saving...' : `Validate (${selectedProducts.length})`}
              </button>
              <button 
                className="reject-button" 
                onClick={onReject}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {content === 'thankYou' && (
          <div className="popup-thankyou">
            <h2>Thank You!</h2>
            <img src={acceptIcon} alt="Accepted" className="accept-icon" />
            <p>Your participation has been confirmed.</p>
            <p>The products have been successfully saved for the live event.</p>
            <button 
              className="close-confirmation-button" 
              onClick={onClose}
            >
              Close
            </button>
          </div>
        )}

        {content === 'rejected' && (
          <div className="popup-rejected">
            <h2>Invitation Declined</h2>
            <p>You have chosen not to participate in this live event.</p>
            <button 
              className="close-confirmation-button" 
              onClick={onClose}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;