import axios from 'axios';
import config from './config';
import md5 from 'md5';

// Configure axios pour envoyer les cookies de session avec chaque requ�te
axios.defaults.withCredentials = true;

// Fonction pour obtenir les produits de WooCommerce
export async function getWooCommerceProducts() {
  try {
    console.log('Fetching WooCommerce products...');
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      }
    });
    console.log('WooCommerce products fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des produits WooCommerce:', error.message);
    throw error; // Relancer l'erreur pour gestion ult�rieure
  }
}

// Fonction pour obtenir les produits d'un vendeur sp�cifique
export async function getWooCommerceVendorProducts(vendorId) {
  try {
    console.log(`Fetching WooCommerce products for vendor ${vendorId}...`);
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      }
    });
    const products = response.data.filter(product => product.store && product.store.id === vendorId);
    console.log(`WooCommerce products for vendor ${vendorId} fetched:`, products);
    return products;
  } catch (error) {
    console.error(`Erreur lors de la r�cup�ration des produits du vendeur ${vendorId} :`, error.message);
    throw error;
  }
}

// Fonction pour obtenir les utilisateurs Dokan
export async function getDokanUsers() {
  try {
    console.log('Fetching Dokan vendors...');
    const response = await axios.get(`${config.dokan.url}stores`);
    console.log('Dokan vendors fetched:', response.data);

    // Ajouter l'URL de Gravatar pour chaque vendeur
    const vendors = response.data.map(vendor => {
      const email = vendor.email;
      const gravatarUrl = email ? `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}` : null;
      return {
        ...vendor,
        gravatarUrl
      };
    });

    return vendors;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des vendeurs Dokan:', error.message);
    throw error;
  }
}

// Fonction pour obtenir les notifications
export async function getNotifications(token) {
  try {
    console.log('Fetching notifications...');
    const response = await axios.get(`${config.apiBaseUrl}/notifications`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Notifications fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des notifications:', error.message);
    throw error;
  }
}

// Fonction pour obtenir les notifications d'un vendeur sp�cifique
export async function getVendorNotifications(IDVendor) {
  try {
    const response = await axios.get(`https://srv493134.hstgr.cloud/api/vendor/notifications?IDVendor=${IDVendor}`);
    return response.data.map(notification => ({
      eventId: notification.eventId, // Assurez-vous que c'est le bon champ
      message: notification.message,
      date: notification.date,
      read: notification.read === 'true'
    }));
  } catch (error) {
    console.error(`Error fetching notifications:`, error);
    throw error;
  }
}

// Fonction pour marquer une notification comme lue
export async function markNotificationAsRead(notificationId) {
  try {
    console.log(`Marking notification ${notificationId} as read...`);
    const response = await axios.put(`https://srv493134.hstgr.cloud/api/events/${notificationId}/read-true`);
    console.log('Notification marked as read:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise � jour de la notification:', error.message);
    throw error;
  }
}

// Fonction pour obtenir les notifications de l'administrateur
export async function getAdminNotifications() {
  try {
    console.log('Fetching admin notifications...');
    const response = await axios.get('https://srv493134.hstgr.cloud/api/admin/notifications');
    
    // V�rification approfondie de la r�ponse
    if (!response.data || !Array.isArray(response.data)) {
      console.error('Invalid notifications data:', response.data);
      return [];
    }

    // Formatage coh�rent des donn�es
    const formattedNotifications = response.data.map(notification => ({
      eventId: notification.eventId,
      message: `Vendor ${notification.IDVendor} has ${notification.status.toLowerCase()} the invitation`,
      date: notification.DateNotif || new Date().toISOString(),
      adminRead: notification.adminRead === 'true'
    }));

    console.log('Formatted admin notifications:', formattedNotifications);
    return formattedNotifications;
  } catch (error) {
    console.error('Error fetching admin notifications:', error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
}

// Fonction pour marquer une notification d'administrateur comme lue
export async function markAdminNotificationAsRead(eventId) {
  try {
    const response = await axios.put(
      `https://srv493134.hstgr.cloud/api/events/${eventId}/admin-read-true`,
      {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error marking notification:', error.response?.data || error.message);
    throw error;
  }
}

// Fonction pour r�pondre � une invitation de Live Event
export const respondToLiveEvent = async (token, eventId, response) => {
  try {
    console.log(`respondToLiveEvent called with eventId: ${eventId} and response: ${response}`);
    const url = `${config.apiBaseUrl}/live-events/${eventId}/response`;
    const res = await axios.post(url, { response }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('Response sent:', res.data);
  } catch (error) {
    console.error('Erreur lors de l\'envoi de la r�ponse:', error.message);
    throw error;
  }
};

// Fonction pour mettre � jour le statut d'un �v�nement
export const updateEventStatus = async (eventId, status) => {
  try {
    const url = `https://srv493134.hstgr.cloud/api/events/${eventId}`;
    console.log(`Updating event status for event ${eventId} to ${status}...`);
    console.log(`URL: ${url}`);
    const response = await axios.put(url, { status });
    console.log('Event status updated:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise � jour du statut de l\'�v�nement:', error.message);
    throw error;
  }
};

// Fonction pour cr�er un �v�nement en direct
export const createLiveEvent = async (eventData) => {
  try {
    console.log('Creating live event with data:', eventData);
    const url = `https://srv493134.hstgr.cloud/api/events`;
    const response = await axios.post(url, eventData);
    console.log('Live event created:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating live event:', error.response?.data || error.message);
    throw error;
  }
};

// Fonction pour obtenir tous les �v�nements
export const getEvents = async () => {
  try {
    const url = `https://srv493134.hstgr.cloud/api/events`;
    console.log(`Fetching events...`);
    const response = await axios.get(url);
    console.log('Events fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des �v�nements:', error.message);
    throw error;
  }
};

// Fonction pour mettre � jour les produits d'un �v�nement
export const updateEventProducts = async (eventId, products) => {
  try {
    const url = `https://srv493134.hstgr.cloud/api/events/${eventId}/products`;
    console.log(`Updating products for event ${eventId}...`);
    const response = await axios.post(url, { products });
    console.log('Products updated:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise � jour des produits de l\'�v�nement:', error.message);
    throw error;
  }
};
