// src/components/VendorPopup.js

import React from 'react';
import './VendorPopup.css'; // Importez le fichier CSS

const VendorPopup = ({ isOpen, onClose, vendors, onAccept }) => {
  if (!isOpen) {
    return null;
  }

  const handleAccept = () => {
    const selectedVendorId = document.querySelector('select').value;
    onAccept(selectedVendorId);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <button onClick={onClose}>Close</button>
        <h2>Vendors</h2>
        <select multiple>
          {vendors.map(vendor => (
            <option key={vendor.id} value={vendor.id}>
              {vendor.first_name} {vendor.last_name}
            </option>
          ))}
        </select>
        <button onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default VendorPopup;