import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './LiveEvent.css'; // Assurez-vous d'avoir un fichier CSS pour g�rer les styles.

const LiveEvent = () => {
  const { eventId } = useParams(); // R�cup�re l'eventId depuis l'URL
  const [videoStream, setVideoStream] = useState(null);
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    date: '',
    vendor: ''
  });
  const [generatedLink, setGeneratedLink] = useState(''); // �tat pour g�rer le lien g�n�r�

  useEffect(() => {
    // Utilisation de l'URL de flux vid�o sp�cifi�e
    setVideoStream(`https://srv493134.hstgr.cloud/live/${eventId}`);
  }, [eventId]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`https://srv493134.hstgr.cloud/api/events/${eventId}`);
        setEventData(response.data);
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des donn�es de l\'�v�nement:', error);
      }
    };

    fetchEventData();
  }, [eventId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://srv493134.hstgr.cloud/api/events', eventData);
      const newEventId = response.data.eventId;
      const newEventLink = `https://srv493134.hstgr.cloud/live/${newEventId}`;
      setGeneratedLink(newEventLink); // Mettre � jour l'�tat avec le lien g�n�r�
    } catch (error) {
      console.error('Erreur lors de la cr�ation de l\'�v�nement en direct:', error);
    }
  };

  return (
    <div className="container">
      {/* Bloc des commentaires */}
      <div className="bloc blocchat">
        {/* Contenu vide */}
      </div>

      {/* Bloc de la vid�o en direct */}
      <div className="bloc blocvideo">
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
        {videoStream ? (
          <video id="liveVideo" src={videoStream} autoPlay controls></video>
        ) : (
          <p>Le live n'est pas encore commenc�</p>
        )}
      </div>

      {/* Bloc des produits */}
      <div className="bloc blocproduit">
        {/* Contenu vide */}
      </div>

      {/* Formulaire de cr�ation d'�v�nement en direct */}
      <form onSubmit={handleSubmit}>
        <input type="text" name="title" value={eventData.title} onChange={handleChange} placeholder="Title" />
        <input type="text" name="description" value={eventData.description} onChange={handleChange} placeholder="Description" />
        <input type="date" name="date" value={eventData.date} onChange={handleChange} />
        <input type="text" name="vendor" value={eventData.vendor} onChange={handleChange} placeholder="Vendor" />
        <button type="submit">Publish</button>
      </form>

      {/* Afficher le lien g�n�r� */}
      {generatedLink && (
        <div className="generated-link">
          <p>Live Event Link: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a></p>
        </div>
      )}
    </div>
  );
};

export default LiveEvent;