// Menu.js
import React from 'react';
import { Home, VideoCall, History, Settings, BarChart, Notifications } from '@mui/icons-material';

const Menu = ({ activeMenu, handleMenuClick, handleSubMenuToggle, handleSubMenuClick, isSubMenuVisible }) => {
  return (
    <ul className="menu1">
      <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
        <Home />
        Home
      </li>
      <li className={activeMenu === 'Live Events' ? 'active' : ''} onClick={() => handleMenuClick('Live Events')}>
        <VideoCall />
        Live Events
      </li>
      <li className={activeMenu === 'Private Lives' ? 'active' : ''} onClick={() => handleMenuClick('Private Lives')}>
        <VideoCall />
        Private Lives
      </li>
      <li className={activeMenu === 'History' ? 'active' : ''} onClick={() => handleMenuClick('History')}>
        <History />
        History
      </li>
      <li className={activeMenu === 'Control Panel' ? 'active' : ''} onClick={() => handleMenuClick('Control Panel')}>
        <Settings />
        Control Panel
      </li>
      <li className={activeMenu === 'Notifications' ? 'active' : ''} onClick={() => handleMenuClick('Notifications')}>
        <Notifications />
        Notifications
      </li>
      <li
        className={activeMenu === 'Analytics' ? 'active' : ''}
        onMouseEnter={handleSubMenuToggle}
        onMouseLeave={handleSubMenuToggle}
      >
        <BarChart />
        Analytics
        {isSubMenuVisible && (
          <ul className="submenu">
            <li onClick={() => handleSubMenuClick('Live Events')}>Live Events</li>
            <li onClick={() => handleSubMenuClick('Private Lives')}>Private Lives</li>
            <li onClick={() => handleSubMenuClick('Stores')}>Stores</li>
          </ul>
        )}
      </li>
    </ul>
  );
};

export default Menu;