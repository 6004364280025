import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import './Dashboard.css';
import { getDokanUsers, getAdminNotifications, markAdminNotificationAsRead, createLiveEvent, getEvents } from './api';
import axios from 'axios';
import { formatDistanceToNow, parseISO, isValid, format, addHours, setHours, setMinutes, setSeconds } from 'date-fns';
import Menu from './Menu';
import { Add } from '@mui/icons-material';
import formatEventDate from './utils/formatEventDate'; // Importez la fonction utilitaire

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function Dashboard({ products, vendors }) {
  const { eventName } = useParams();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(localStorage.getItem('activeMenu') || 'Home');
  const [subMenuSelection, setSubMenuSelection] = useState(localStorage.getItem('subMenuSelection') || null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [adminName, setAdminName] = useState('Admin');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('form');
  const [popupMessage, setPopupMessage] = useState('');
  const [datenle, setDatenle] = useState('');
  const [stores, setStores] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [olderEvents, setOlderEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [showAllUpcomingEvents, setShowAllUpcomingEvents] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null); // �tat pour stocker le vendeur s�lectionn�
  const [selectedVendorDetails, setSelectedVendorDetails] = useState(null); // �tat pour stocker les d�tails du vendeur s�lectionn�
  const [isStatusVisible, setIsStatusVisible] = useState(false); // �tat pour g�rer la visibilit� du bloc "bloch3hstatus"
  const navigate = useNavigate();
  const unreadCount = notifications.filter(n => !n.adminRead).length;

  useEffect(() => {
    async function fetchVendors() {
      try {
        const vendors = await getDokanUsers();
        console.log('Vendors data:', vendors);
        setVendorList(vendors);
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des vendeurs:', error);
      }
    }
    fetchVendors();
  }, []);

  useEffect(() => {
    const fetchLiveEvents = async () => {
      try {
        const events = await getEvents();
        console.log('Live events data:', events);
        setLiveEvents(events);
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des donn�es des live events:', error);
      }
    };
    fetchLiveEvents();
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    const now = new Date();
    const pastEvents = liveEvents.filter(event => {
      const endDate = addHours(parseISO(event.startDate), 3);
      return endDate < now;
    });
    setOlderEvents(pastEvents);
  }, [liveEvents]);

  useEffect(() => {
    // R�initialiser l'�tat isStatusVisible lorsque l'URL change
    setIsStatusVisible(false);
  }, [location.pathname]);

  const fetchNotifications = async () => {
    try {
      const notifications = await getAdminNotifications();
      
      // V�rification suppl�mentaire
      if (!Array.isArray(notifications)) {
        console.error('Received notifications is not an array:', notifications);
        setNotifications([]);
        return;
      }

      setNotifications(notifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]); // Assure qu'un tableau vide est d�fini en cas d'erreur
    }
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    setSubMenuSelection(null);
    localStorage.setItem('activeMenu', menuName);
    localStorage.removeItem('subMenuSelection');
    navigate('/dashboard');
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (subMenuName) => {
    setSubMenuSelection(subMenuName);
    setIsSubMenuVisible(false);
    localStorage.setItem('subMenuSelection', subMenuName);
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      window.location.href = 'https://live-discount.com';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleCreateLiveEventClick = () => {
    setShowPopup(true);
    setPopupContent('form');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePublish = async () => {
    const eventId = Math.floor(Math.random() * 1000000);
    const liveLink = `https://srv493134.hstgr.cloud/live/${eventId}`;
    const eventName = `Live Event ${eventId}`;
    
    // Convertir en format MySQL (YYYY-MM-DD HH:MM:SS)
    const formatDateForMySQL = (date) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')} ${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}:${String(d.getSeconds()).padStart(2,'0')}`;
    };

    const startDate = formatDateForMySQL(datenle);
    const now = formatDateForMySQL(new Date());

    const eventData = {
      eventName,
      liveLink,
      startDate, // Format MySQL compatible
      IDVendor: stores.map(store => store.IDVendor),
      name: stores.map(store => store.name),
      startDateVendor: stores.map(store => store.startDateVendor || '10:00:00'),
      endDateVendor: stores.map(store => store.endDateVendor || '11:00:00'),
      eventIdVendor: stores.map(() => Math.floor(Math.random() * 1000000)),
      status: 'Pending',
      Date: now,
      DateNotif: now,
      Products: JSON.stringify([]),
      read: 'false',
      adminRead: 'false'
    };

    console.log('Sending event data:', eventData);

    try {
      const response = await createLiveEvent(eventData);
      console.log('Event created:', response);
      
      setPopupMessage(`Live event created successfully! Link: ${liveLink}`);
      setPopupContent('confirmation');
      
      // Refresh events list
      const events = await getEvents();
      setLiveEvents(events);
    } catch (error) {
      console.error('Error creating event:', error);
      setPopupMessage('Failed to create event. Please check console for details.');
    }
  };

  const handleStoreSelection = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);

    const selectedStores = selectedOptions.map(option => ({
      IDVendor: option.value,
      name: option.text
    }));
    setStores(selectedStores);
  };

  const handleNotificationClick = async (notification) => {
    try {
      if (!notification || !notification.eventId) return;

      // Mise � jour optimiste
      setNotifications(prev => 
        prev.map(n => 
          n.eventId === notification.eventId ? { ...n, adminRead: true } : n
        )
      );

      await markAdminNotificationAsRead(notification.eventId);
    } catch (error) {
      // Rollback en cas d'erreur
      setNotifications(prev => 
        prev.map(n => 
          n.eventId === notification.eventId ? { ...n, adminRead: false } : n
        )
      );
      console.error('Error:', error);
    }
  };

  const toggleNotificationList = () => {
    setShowNotificationList(!showNotificationList);
  };

  const formatNotificationDate = (dateString) => {
    if (!dateString) return 'No date available';
    const date = parseISO(dateString);
    return isValid(date) ? formatDistanceToNow(date, { addSuffix: true }) : 'Invalid date';
  };

  const calculateTimeRemaining = (startDate) => {
    const total = Date.parse(startDate) - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  };

  const startCountdown = (startDate, setTimeRemaining) => {
    const updateCountdown = () => {
      const timeRemaining = calculateTimeRemaining(startDate);
      setTimeRemaining(timeRemaining);
      if (timeRemaining.total <= 0) {
        clearInterval(intervalId);
      }
    };
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);
    return intervalId;
  };

  const Countdown = ({ startDate }) => {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(startDate));

    useEffect(() => {
      const intervalId = startCountdown(startDate, setTimeRemaining);
      return () => clearInterval(intervalId);
    }, [startDate]);

    return (
      <div className="countdown">
        {`${String(timeRemaining.days).padStart(2, '0')} : ${String(timeRemaining.hours).padStart(2, '0')} : ${String(timeRemaining.minutes).padStart(2, '0')} : ${String(timeRemaining.seconds).padStart(2, '0')}`}
      </div>
    );
  };

  const calculateElapsedTime = (endTime) => {
    const now = new Date();
    const end = new Date(endTime);
    const elapsed = now - end;

    const hours = Math.floor(elapsed / (1000 * 60 * 60));
    const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleVendorClick = (vendorData, event) => {
    const eventDate = parseISO(event.startDate);
    const [startHour, startMinute, startSecond] = event.startDateVendor.split(':').map(Number);
    const [endHour, endMinute, endSecond] = event.endDateVendor.split(':').map(Number);

    const startDate = setSeconds(setMinutes(setHours(eventDate, startHour), startMinute), startSecond);
    const endDate = setSeconds(setMinutes(setHours(eventDate, endHour), endMinute), endSecond);

    const formattedStartTime = format(startDate, 'HH:mm');
    const formattedEndTime = format(endDate, 'HH:mm');

    setSelectedVendor(vendorData);
    setSelectedVendorDetails({
      startTime: formattedStartTime,
      endTime: formattedEndTime
    });
    setIsStatusVisible(true); // Affiche le bloc "bloch3hstatus"
  };

  const renderLiveEvent = (event, index) => (
    <div key={index} className="live-event">
      <div className="event-date" style={{ fontWeight: 'bold' }}>{formatEventDate(event.Date)}</div>
      <div className="event-time">{event.startDateVendor} - {event.endDateVendor}</div>
      <div className="vendor-info">
        {Array.isArray(event.stores) && event.stores.map(vendor => {
          const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
          const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
          return (
            <div key={vendor.IDVendor} className="vendor-details">
              {profileUrl && (
                <img
                  src={profileUrl}
                  alt={vendor.name}
                  className="vendor-icon"
                  onError={(e) => {
                    e.target.style.display = 'none';
                    console.error('Image load error:', e);
                  }}
                />
              )}
              <div className="vendor-name">{vendor.name}</div>
            </div>
          );
        })}
      </div>
      <div className="product-count">{event.Products.length} Products</div>
    </div>
  );

  const renderContent = () => {
    if (eventName) {
      const event = liveEvents.find(event => event.eventName === eventName);
      if (!event) {
        return <div>Event not found</div>;
      }

      // Filtrer les �v�nements pour afficher que "Pending"
      const pendingVendors = Array.isArray(event.stores) ? event.stores.filter(vendor => {
        const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
        return vendorData && vendorData.status === 'Pending';
      }) : [];

      return (
        <div>
          <div className="bloch3hmenu"></div>
          <h2 className="main-settings-title">Main settings</h2>
          <div className="bloch3-details">
            <div className="bloch3ledetail">
              <div className="event-date">{formatEventDate(event.startDate)}</div>
              <div className="product-count">{event.Products.length} products TOTAL</div>
              <div className="event-starts-in">THE EVENT STARTS IN:</div>
              <Countdown startDate={event.startDate} />
            </div>
            <div className="bloch3lestore">
              <div className="store-title">Stores</div>
              <div className="confirmed-stores-text">Confirmed stores:</div>
              <div className="carousel-container">
                <div className="carousel">
                  {Array.isArray(event.stores) && event.stores.map(store => {
                    const vendorData = vendorList.find(v => v.id && v.id.toString() === store.IDVendor.toString());
                    const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                    return (
                      <div key={store.IDVendor} className="vendor-details" onClick={() => handleVendorClick(vendorData, event)}>
                        {profileUrl && (
                          <img
                            src={profileUrl}
                            alt={store.name}
                            className="vendor-icon"
                            onError={(e) => {
                              e.target.style.display = 'none';
                              console.error('Image load error:', e);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pending-vendors-carousel">
                <h4>Pending Vendors</h4>
                <div className="carousel">
                  {Array.isArray(event.stores) && event.stores.filter(vendor => {
                    const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
                    return vendorData && vendorData.status === 'Pending';
                  }).map((vendor, index) => {
                    const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
                    const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                    return (
                      <div key={index} className="vendor-details">
                        {profileUrl && (
                          <img
                            src={profileUrl}
                            alt={vendor.name}
                            className="vendor-icon"
                            onError={(e) => {
                              e.target.style.display = 'none';
                              console.error('Image load error:', e);
                            }}
                          />
                        )}
                        <div className="vendor-name">{vendor.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bloch3leshare"></div>
          </div>
          <div className={`bloch3hstatus ${isStatusVisible ? 'visible' : ''}`}>
            <div className="bloch3hstatus1">
              {selectedVendor && (
                <>
                  <div className="vendor-name">{selectedVendor.store_name}</div>
                  <img
                    src={selectedVendor.gravatarUrl}
                    alt={selectedVendor.store_name}
                    className="vendor-icon"
                    onError={(e) => {
                      e.target.style.display = 'none';
                                          console.error('Image load error:', e);
                  }}
                />
              </>
            )}
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3hstatus2">
            {selectedVendorDetails && (
              <>
                <div>{`${selectedVendorDetails.startTime} - ${selectedVendorDetails.endTime}`}</div>
              </>
            )}
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3hstatus3">Content 3</div>
        </div>
      </div>
    );
  }

  // Filtrer les �v�nements pour afficher que "Valid"
  const validLiveEvents = liveEvents.filter(event => event.status === 'Valid');
  console.log('Valid Live Events:', validLiveEvents);
  
  // Filtrer les �v�nements pour afficher que "Pending"
  const PendingLiveEvents = liveEvents.filter(event => event.status === 'Pending');
  console.log('Pending Live Events:', PendingLiveEvents);

  const upcomingLiveEvents = validLiveEvents.filter(event => new Date(event.startDate) > new Date());
  console.log('Upcoming Live Events:', upcomingLiveEvents);

  const uniqueEvents = {};

  upcomingLiveEvents.forEach(event => {
    if (!uniqueEvents[event.eventId]) {
      uniqueEvents[event.eventId] = {
        ...event,
        totalProducts: event.Products.length,
        startDateVendor: event.startDateVendor,
        endDateVendor: event.endDateVendor
      };
    } else {
      uniqueEvents[event.eventId].totalProducts += event.Products.length;
      uniqueEvents[event.eventId].endDateVendor = event.endDateVendor;
    }
  });

  const renderUpcomingEvents = () => {
    const eventsToShow = showAllUpcomingEvents ? Object.values(uniqueEvents) : Object.values(uniqueEvents).slice(0, 3);
    return eventsToShow.map((event, index) => {
      const startDate = parseISO(event.startDate);
      const endDate = addHours(startDate, 3);
      const formattedStartTime = format(startDate, 'HH:mm');
      const formattedEndTime = format(endDate, 'HH:mm');

      return (
        <Link to={`/dashboard/${event.eventName}`} key={index} className="bloch3ueh" style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="bloch3ueh1">
            <div className="event-date" style={{ fontWeight: 'bold' }}>{formatEventDate(event.Date)}</div>
            <div className="event-time">{formattedStartTime} - {formattedEndTime}</div>
            <div className="product-count">{event.totalProducts} products TOTAL</div>
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3ueh2">
            <div>THE EVENT STARTS IN:</div>
            <Countdown startDate={event.startDate} />
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3ueh3">
            <div>PARTICIPATING STORES</div>
            <div className="carousel-container">
              <div className="carousel">
                {Array.isArray(event.stores) && event.stores.map(store => {
                  const vendorData = vendorList.find(v => v.id && v.id.toString() === store.IDVendor.toString());
                  const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                  return (
                    <div key={store.IDVendor} className="vendor-details">
                      {profileUrl && (
                        <img
                          src={profileUrl}
                          alt={store.name}
                          className="vendor-icon"
                          onError={(e) => {
                            e.target.style.display = 'none';
                            console.error('Image load error:', e);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Link>
      );
    });
  };

  const renderOlderEvents = () => {
    return olderEvents.map((event, index) => {
      const startDate = parseISO(event.startDate);
      const endDate = addHours(startDate, 3);
      const formattedStartTime = format(startDate, 'HH:mm');
      const formattedEndTime = format(endDate, 'HH:mm');

      return (
        <Link to={`/dashboard/${event.eventName}`} key={index} className="bloch3ueh" style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="bloch3ueh1">
            <div className="event-date" style={{ fontWeight: 'bold' }}>{formatEventDate(event.Date)}</div>
            <div className="event-time">{formattedStartTime} - {formattedEndTime}</div>
            <div className="product-count">{event.Products.length} products TOTAL</div>
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3ueh2">
            <div>THE EVENT ENDED:</div>
            <div className="countdown">
              {calculateElapsedTime(endDate)}
            </div>
          </div>
          <div className="vertical-divider"></div>
          <div className="bloch3ueh3">
            <div>PARTICIPATING STORES</div>
            <div className="carousel-container">
              <div className="carousel">
                {Array.isArray(event.stores) && event.stores.map(store => {
                  const vendorData = vendorList.find(v => v.id && v.id.toString() === store.IDVendor.toString());
                  const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                  return (
                    <div key={store.IDVendor} className="vendor-details">
                      {profileUrl && (
                        <img
                          src={profileUrl}
                          alt={store.name}
                          className="vendor-icon"
                          onError={(e) => {
                            e.target.style.display = 'none';
                            console.error('Image load error:', e);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Link>
      );
    });
  };

  switch (activeMenu) {
    case 'Home':
      return (
        <div id="bloch3home">
          <button className="boutoncle" onClick={handleCreateLiveEventClick}>
            <Add /> CREATE A LIVE EVENT
          </button>
          <div className="bloch3ple">
            <div className="pending-live-events">
              <h4>Pending LIVE EVENTS</h4>
              <p>Here are the stores who ask to take part in an upcoming LIVE EVENT</p>
            </div>
            <div className="carousel-container">
              <div className="carousel">
                {PendingLiveEvents.map((event, index) => renderLiveEvent(event, index))}
              </div>
            </div>
          </div>
        </div>
      );
    case 'Live Events':
      return (
        <div id="bloch3le">
          <div className="bloch3ple">
            <div className="pending-live-events">
              <h4>Pending LIVE EVENTS</h4>
              <p>Here are the stores who ask to take part in an upcoming LIVE EVENT</p>
            </div>
            <div className="carousel-container">
              <div className="carousel">
                {PendingLiveEvents.map((event, index) => renderLiveEvent(event, index))}
              </div>
            </div>
          </div>
          <div className="titreue">Upcoming events</div>
          <div className="bloch3ue">
            {Object.values(uniqueEvents).length === 0 ? (
              <p>No upcoming events</p>
            ) : (
              <>
                {renderUpcomingEvents()}
                {!showAllUpcomingEvents && Object.values(uniqueEvents).length > 3 && (
                  <button className="show-more-button" onClick={() => setShowAllUpcomingEvents(true)}>
                    Show More
                  </button>
                )}
              </>
            )}
          </div>
          <div className="titreoe">Older events</div>
          <div className="bloch3oe">
            {olderEvents.length === 0 ? (
              <p>No older events</p>
            ) : (
              renderOlderEvents()
            )}
          </div>
        </div>
      );
    case 'Private Lives':
      return (
        <div id="bloch3pl">
          <div className="bloch3prl">Contenu bloch3prl</div>
          <div className="titreueprivate">Upcoming events</div>
          <div className="bloch3ueprivate">Contenu bloch3ueprivate</div>
          <div className="titreoeprivate">Older events</div>
          <div className="bloch3oeprivate">Contenu bloch3oeprivate</div>
        </div>
      );
    case 'History':
      return <div id="bloch3history">Contenu pour l'historique des �v�nements</div>;
    case 'Control Panel':
      return <div id="bloch3cp">Contenu pour le panneau de configuration</div>;
    case 'Notifications':
      return (
        <div id="bloch3notifications">
          <h2>Notifications</h2>
          {notifications.map(notification => (
            <div
              key={notification.id}
              className={`notification-item ${notification.read ? 'read' : 'unread'}`}
              onClick={() => handleNotificationClick(notification.id)}
            >
              <span>{notification.message}</span>
              <span className="notification-date">
                {formatNotificationDate(notification.date)}
              </span>
              <hr />
            </div>
          ))}
        </div>
      );
    default:
      return <div id="bloch3">Contenu par d�faut</div>;
  }
};

const formatEventDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "MMMM do, EEEE");
};

const event = liveEvents.find(event => event.eventName === eventName);
const eventDate = event ? formatEventDate(event.startDate) : '';

return (
  <div className="dashboard-container">
    <div className="bloc1">
      <div className="logo-container">
        <img
          src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
          alt="Logo"
          className="logo"
        />
      </div>
      <Menu
        activeMenu={activeMenu}
        handleMenuClick={handleMenuClick}
        handleSubMenuToggle={handleSubMenuToggle}
        handleSubMenuClick={handleSubMenuClick}
        isSubMenuVisible={isSubMenuVisible}
      />
      <button className="bouton1" onClick={() => (window.location.href = 'https://live-discount.com/')}>
        GO TO DASHBOARD
      </button>
    </div>

    <div className="bloc2">
      <div className="bloch1">
        <span className="admin-name">{adminName}</span>

        <span
          className="admin-icon"
          onMouseEnter={handleProfileHover}
          onMouseLeave={handleProfileLeave}
        >
          <img
            src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
            alt="Admin Icon"
          />
          {showLogoutMenu && (
            <div className="admin-menu">
              <a href="#" className="logout-btn" onClick={handleLogout}>
                Logout
              </a>
            </div>
          )}
        </span>

        <span className="notifications-icon" onClick={toggleNotificationList}>
          <img
            src="https://img.icons8.com/material-outlined/24/alarm.png"
            alt="Notifications"
          />
          {unreadCount > 0 && (
            <span className="notification-count">
              {unreadCount}
            </span>
          )}
        </span>
      </div>

      {showNotificationList && (
        <div className="notification-list">
          {notifications.map(notification => (
            <div
              key={notification.eventId}
              className={`notification-item ${notification.adminRead ? 'read' : 'unread'}`}
              onClick={() => handleNotificationClick(notification)}
            >
              <span>{notification.message}</span>
              <span className="notification-date">
                {formatNotificationDate(notification.date)}
              </span>
              <hr />
            </div>
          ))}
        </div>
      )}

      <div className="bloch2">
        {eventName ? (
          <span className="event-date-settings">
            {eventDate}
          </span>
        ) : (
          <span className="welcome-text">
            Welcome, <strong style={{ color: '#7fd2f3' }}>{adminName}</strong>
          </span>
        )}
      </div>

      <div className="bloch3">
        {eventName ? (
          <div>
            <div className="bloch3hmenu"></div>
            <h2 className="main-settings-title">Main settings</h2>
            <div className="bloch3-details">
              <div className="bloch3ledetail">
                <div className="event-date">{event ? format(parseISO(event.startDate), "MMMM do, EEEE") : ''}</div>
                <div className="product-count">{event ? `${event.Products.length} products TOTAL` : ''}</div>
                <div className="event-starts-in">THE EVENT STARTS IN:</div>
                {event && <Countdown startDate={event.startDate} />}
              </div>
              <div className="bloch3lestore">
                <div className="store-title">Stores</div>
                <div className="confirmed-stores-text">Confirmed stores:</div>
                <div className="carousel-container">
                  <div className="carousel">
                    {Array.isArray(event.stores) && event.stores.map(store => {
                      const vendorData = vendorList.find(v => v.id && v.id.toString() === store.IDVendor.toString());
                      const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                      return (
                        <div key={store.IDVendor} className="vendor-details" onClick={() => handleVendorClick(vendorData, event)}>
                          {profileUrl && (
                            <img
                              src={profileUrl}
                              alt={store.name}
                              className="vendor-icon"
                              onError={(e) => {
                                e.target.style.display = 'none';
                                console.error('Image load error:', e);
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="pending-vendors-carousel">
                  <h4>Pending Vendors</h4>
                  <div className="carousel">
                    {Array.isArray(event.storeVendor) && event.storeVendor.filter(vendor => {
                      const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
                      return vendorData && vendorData.status === 'Pending';
                    }).map((vendor, index) => {
                      const vendorData = vendorList.find(v => v.id && v.id.toString() === vendor.IDVendor.toString());
                      const profileUrl = vendorData && vendorData.gravatarUrl ? vendorData.gravatarUrl : null;
                      return (
                        <div key={index} className="vendor-details">
                          {profileUrl && (
                            <img
                              src={profileUrl}
                              alt={vendor.name}
                              className="vendor-icon"
                              onError={(e) => {
                                e.target.style.display = 'none';
                                console.error('Image load error:', e);
                              }}
                            />
                          )}
                          <div className="vendor-name">{vendor.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="bloch3leshare"></div>
            </div>
            <div className={`bloch3hstatus ${isStatusVisible ? 'visible' : ''}`}>
              <div className="bloch3hstatus1">
                {selectedVendor && (
                  <>
                    <div className="vendor-name">{selectedVendor.store_name}</div>
                    <img
                      src={selectedVendor.gravatarUrl}
                      alt={selectedVendor.store_name}
                      className="vendor-icon"
                      onError={(e) => {
                        e.target.style.display = 'none';
                        console.error('Image load error:', e);
                      }}
                    />
                  </>
                )}
              </div>
              <div className="vertical-divider"></div>
              <div className="bloch3hstatus2">
                {selectedVendorDetails && (
                  <>
                    <div>{`${selectedVendorDetails.startTime} - ${selectedVendorDetails.endTime}`}</div>
                  </>
                )}
              </div>
              <div className="vertical-divider"></div>
              <div className="bloch3hstatus3">Content 3</div>
            </div>
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </div>

    {showPopup && (
      <div className="popup-overlay">
        <div className="popupnle">
          {popupContent === 'form' ? (
            <div>
              <button onClick={handlePopupClose}>&larr; Back</button>
              <h2 className="popup-title">Create a New Live Event</h2>
              <form className="formnle">
                <label htmlFor="datenle">Date and Time:</label>
                <input
                  type="datetime-local"
                  id="datenle"
                  name="datenle"
                  value={datenle}
                  onChange={(e) => setDatenle(e.target.value)}
                />
                <label htmlFor="stores">Select Stores:</label>
                <select
                  id="stores"
                  name="stores"
                  multiple
                  value={stores.map(store => store.IDVendor)}
                  onChange={handleStoreSelection}
                >
                  {vendorList.map(vendor => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.store_name}
                    </option>
                  ))}
                </select>
              </form>
              <div className="popup-actions">
                <button className="popup-delete" onClick={handlePopupClose}>Cancel</button>
                <button className="popup-publish" onClick={handlePublish}>Publish</button>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="popup-title">Event Created</h2>
              <p>{popupMessage}</p>
                            <button className="popup-close" onClick={handlePopupClose}>Close</button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
}

export default Dashboard;
