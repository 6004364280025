import React, { useState, useEffect } from 'react';
import './VendorDashboard.css';
import { Home, VideoCall, History, SupportAgent, Store, Lock } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { getWooCommerceVendorProducts, getVendorNotifications, markNotificationAsRead, updateEventStatus } from './api';
import Popup from './Popup';
import { formatDistanceToNow, parseISO, isValid } from 'date-fns';

function VendorDashboard() {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [vendorName, setVendorName] = useState('');
  const [vendorSubscription, setVendorSubscription] = useState('Free Plan');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('initial');
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [storeVendorId, setStoreVendorId] = useState('');

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    
    if (token) {
      localStorage.setItem('token', token);
      navigate('/vendor_dashboard', { replace: true });
    } else {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        navigate('/');
      }
    }

    const fetchVendorDetails = (token) => {
      const url = `https://live-discount.com/wp-json/wp/v2/users/me?_=${new Date().getTime()}`;
      
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch vendor details');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.id) {
          setVendorName(data.name);
          setVendorSubscription(data.subscription || 'Free Plan'); 
          setStoreVendorId(data.id);
        } else {
          setVendorName('Vendor');
        }
      })
      .catch(error => {
        console.error('Error fetching vendor details:', error);
        setVendorName('Vendor');
      });
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      fetchVendorDetails(storedToken);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (storeVendorId) {
      fetchNotifications(storeVendorId);
    }
  }, [storeVendorId]);

  const fetchVendorProducts = async (vendorId) => {
    try {
      const products = await getWooCommerceVendorProducts(vendorId);
      setVendorProducts(products);
    } catch (error) {
      console.error('Error fetching vendor products:', error.message);
    }
  };

  const fetchNotifications = async (vendorId) => {
    try {
      const notifications = await getVendorNotifications(vendorId);
      if (Array.isArray(notifications)) {
        setNotifications(notifications.map(notif => ({
          ...notif,
          eventId: notif.eventId || notif.id || Math.random().toString(36).substring(7)
        })));
      } else {
        console.error('Notifications fetched are not an array:', notifications);
        setNotifications([]);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
      setNotifications([]);
    }
  };

  const handleResponse = async (eventId, response) => {
    try {
      if (response === 'accepted') {
        await fetchVendorProducts(storeVendorId);
        setPopupContent('products');
      } else {
        await updateEventStatus(eventId, 'Refused');
        setPopupContent('rejected');
      }
      setNotifications(notifications.filter(notification => notification.eventId !== eventId));
    } catch (error) {
      console.error('Error sending response:', error.message);
    }
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = 'https://live-discount.com';
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleNotificationClick = async (notificationId) => {
    try {
      const notification = notifications.find(n => n.eventId === notificationId);
      if (!notification) {
        console.error('Notification not found:', notificationId);
        return;
      }

      await markNotificationAsRead(notification.eventId);
      
      setNotifications(notifications.map(n => 
        n.eventId === notification.eventId ? { ...n, read: true } : n
      ));
      
      setSelectedEventId(notification.eventId);
      setShowPopup(true);
      setPopupContent('initial');
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  };

  const toggleNotificationList = () => {
    setShowNotificationList(!showNotificationList);
  };

  const formatNotificationDate = (dateString) => {
    if (!dateString) return 'No date available';
    const date = parseISO(dateString);
    return isValid(date) ? formatDistanceToNow(date, { addSuffix: true }) : 'Invalid date';
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Home':
        return (
          <div id="bloch3home">
            <div className="cadre-abonnement">
              <h5>{vendorSubscription} </h5>
              <a href="https://live-discount.com" className="upgrade-link"> Upgrade Now</a>
            </div>

            <div className="flex-container">
              <div className="cadrele">
                <div className="block-content">LIVE EVENT</div>
                <button className="join-button">Join a Live Event</button>
              </div>
              <div className="cadreule">
                <div className="block-content">PRIVATE LIVE</div>
                <button className="join-button">Create a Private Live</button>
              </div>
              <div className="cadrenew">
                <div className="block-content">Renew Content<br/><br/><br/><br/><br/><br/><br/></div>
              </div>
            </div>

            <div className="flex-container">
              <div className="cadrepl">
                <div className="block-content">Private Lives Content</div>
              </div>
              <div className="cadreupl">
                <div className="block-content">Upcoming Private Lives Content</div>
              </div>
              <div></div>
              <div></div>
            </div>

            <div className="cadretotal">Total Content</div>
          </div>
        );
      case 'Live Events':
        return <div id="bloch3le">Here are your live events</div>;
      case 'Private Lives':
        return <div id="bloch3pl">Here are your private broadcasts</div>;
      case 'Products':
        return (
          <div id="bloch3products">
            <h2>Manage your products</h2>
            <ul>
              {vendorProducts.map(product => (
                <li key={product.id}>{product.name}</li>
              ))}
            </ul>
          </div>
        );
      case 'History':
        return <div id="bloch3history">Event history</div>;
      case 'Support':
        return <div id="bloch3support">Technical support</div>;
      default:
        return <div id="bloch3">Default content</div>;
    }
  };

  const handleNext = (selectedProducts) => {
  if (popupContent === 'initial') {
    fetchVendorProducts(storeVendorId);
    setPopupContent('products');
  } else if (popupContent === 'products') {
    // Cette fonction devrait d�clencher la mise � jour du statut
    setPopupContent('thankYou');
    
    // Optionnel: Rafra�chir les donn�es
    fetchNotifications(storeVendorId);
  }
};

  const handleReject = async () => {
    try {
      await updateEventStatus(selectedEventId, 'Refused');
      setPopupContent('rejected');
    } catch (error) {
      console.error('Error updating event status:', error.message);
    }
  };

  const saveSelectedProducts = async (eventId, selectedProducts) => {
    try {
      const response = await fetch(`https://srv493134.hstgr.cloud/api/events/${eventId}/products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ products: selectedProducts }),
      });

      if (!response.ok) {
        throw new Error('Failed to save selected products');
      }

      console.log('Selected products saved successfully.');
    } catch (error) {
      console.error('Error saving selected products:', error.message);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="bloc1">
        <div className="logo-container">
          <img
            src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu1">
          <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
            <Home />
            Home
          </li>
          <li className={activeMenu === 'Live Events' ? 'active' : ''} onClick={() => handleMenuClick('Live Events')}>
            <VideoCall />
            Live Events
          </li>
          <li className={activeMenu === 'Private Lives' ? 'active' : ''} onClick={() => handleMenuClick('Private Lives')}>
            <Lock /> 
            Private Lives
          </li>
          <li className={activeMenu === 'Products' ? 'active' : ''} onClick={() => handleMenuClick('Products')}>
            <Store />
            Products
          </li>
          <li className={activeMenu === 'History' ? 'active' : ''} onClick={() => handleMenuClick('History')}>
            <History />
            History
          </li>
          <li className={activeMenu === 'Support' ? 'active' : ''} onClick={() => handleMenuClick('Support')}>
            <SupportAgent />
            Support
          </li>
        </ul>
        <button
          className="bouton1"
          onClick={() => window.location.href = 'https://live-discount.com/'}
        >
          GO TO DASHBOARD
        </button>
      </div>

      <div className="bloc2">
        <div className="bloch1">
          <span className="admin-name">{vendorName}</span>

          <span className="admin-icon"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <img
              src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
              alt="Vendor Icon"
            />
            {showLogoutMenu && (
              <div className="admin-menu">
                <a href="#" className="logout-btn" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </span>

          <span className="notifications-icon" onClick={toggleNotificationList}>
            <img
              src="https://img.icons8.com/material-outlined/24/alarm.png"
              alt="Notifications"
            />
            {notifications.filter(n => !n.read).length > 0 && (
              <span className="notification-count">
                {notifications.filter(n => !n.read).length}
              </span>
            )}
          </span>
        </div>

        {showNotificationList && (
          <div className="notification-list" style={{ height: '300px', overflowY: 'scroll' }}>
            {notifications.map(notification => (
              <div
                key={notification.eventId}
                className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                onClick={() => handleNotificationClick(notification.eventId)}
              >
                <span>{notification.message}</span>
                <span className="notification-date">
                  {formatNotificationDate(notification.date)}
                </span>
                <hr />
              </div>
            ))}
          </div>
        )}

        <div className="bloch2">
          <span className="welcome-text">
            Welcome, <strong style={{ color: '#7fd2f3' }}>{vendorName}</strong>
          </span>
        </div>

        <div className="bloch3">
          {renderContent()}
        </div>
      </div>

      {showPopup && (
  <Popup
    content={popupContent}
    onClose={() => setShowPopup(false)}
    onNext={handleNext}
    onReject={handleReject}
    products={vendorProducts}
    eventId={selectedEventId}
  />
)}
    </div>
  );
}

export default VendorDashboard;